@import "./variables";

//공통
body{
    min-width: 1024px;
    overflow: auto;

	.container{
        max-width: 1048px;
    }
}

@media print {
	body {
		print-color-adjust: exact;
		-webkit-print-color-adjust: exact;
	}
}

html{
	color: $gray-900;
	line-height: normal;
}

a {
	text-decoration: none;
	color: $gray-900;

	&:hover, &:active{
        color: $gray-900;
    }
}

ul, li{
    list-style: none;
}

object{
	pointer-events: none;
}

button{
    background-color: transparent;
    border: none;
}

pre{
	font-family: "Pretendard";
	line-height: inherit;
	margin-bottom: 0;
	font-size: 1rem;
}

.logo {
	display: block;
    width: 112px;
	min-width: 112px;
	height:19px;
	background: url('/images/logo/logo.svg');
    background-size: cover;
}

.check_mark{
	width: 26px;
}

.form-control{
	border: 1px solid $bordergray;
}

#mainSlider{
	.slick-next {
		right: 25px;
	}
	.slick-prev {
		left: 26px;
		z-index: 10;
	}
}

.square {
	width: 100%;

	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}
}

.circle {
	width: 100%;

	&:after {
		content: "";
		display: block;
		border-radius: 50%;
		padding-bottom: 100%;
	}
}

.ellipsis-two-line {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

#filterBtnGroup {
	position: relative;
}

.filter-backdrop {
	position: fixed; 
	top: 0; 
	left: 0; 
	width: 100%; 
	height: 100%; 
}

.filter-count-badge {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	font-size: 0.8rem;
}

//navbar
#gnb {
	&.navbar{
		height: 3.75rem;
		font-size: 0.9rem;

		.navbar-collapse{
			margin-left: 5rem;
			flex-basis: auto;

			.navbar-nav{
				li{
					margin-right: 1.75rem;

					&:last-child{
						margin-right: 0;
					}

					a{
						padding: 0.375rem 0.9375rem;
						text-decoration: none;
						color: $gray-900;

						&:hover {
							color: $primary;
						}

						&.active{
							font-weight: 700;
							background-color: $primary;
							color: $white;
							border-radius: $border-radius-pill;
						}
					}
				}
			}
		}

		.nav_right{
			font-size: 0.8rem;
		}

		.auth_dropdown{
			position: relative;

			.dropdown-menu{
				left: calc(50% - 5.9375rem/2);
				right: auto;
			}
		}

		.dropdown{
			.btn{
				padding: 0.125rem 0.5rem;
				color: $gray-900 !important;
				border: 1px solid $gray-400;
				font-size: 0.8rem;

				&:hover, &:focus{
					background-color: $gray-100;
				}
			}

			&-menu{
				border: 1px solid $gray-400;
				left: auto;
				right: 0;
				width: 5.9375rem;
				min-width:fit-content;
				padding: 0.44rem 0;

				li{
					.dropdown-item{
						margin-left: 0;
						padding: 0.3rem 0.81rem;
						font-size: 0.8rem;

						&:hover{
							background-color: $select;
						}
					}
				}
			}
		}

		//임시
		.company_service{
			a{
				margin-left: 0.75rem;
				padding: 0.25rem 0.625rem;
				background-color: $lightblue;
				border-radius: 1.875rem;
			}
		}
	}
}


//content
.content_wrap{
	min-height: calc(100vh - 3.75rem - 14.625rem);
}

//footer
.footer{
	background-color: #171B22;
	color: $white;

	.footer_box{
		height: 14.625rem;

		.footer_info{
			gap: 3.125rem;

			.footer_logo{
				gap: 0.375rem;

				.logo_white{
					display: block;
					background: url('/images/logo/logo_white.svg');
					background-repeat: no-repeat;
					width: 9.375rem;
					height:1.5625rem;
				}
	
				.brand_title{
					font-size: $body-text-14;
					font-weight: 700;
				}
			}

			.footer_info_group{
				gap: 1rem;

				.footer_info_text{
					gap: 0.375rem;

					&>div{
						p{
							font-size: $body-text-12;

							a{
								color: $white;
							}
						}

						span{
							display: block;
							width: 1px;
							height: 0.5625rem;
							background-color: $disabledtext;
							margin: 0 0.5rem;
						}
					}
				}

				.policies{
					gap: 0.75rem;
					margin-bottom: 0;
					font-size: $body-text-12;

					.nav-link{
						&:nth-child(2){
							font-weight: 700;	
						}
						
						a{
							color: $white;
						}
					}
				}

				.copy{
					font-size: $body-text-12;
				}
			}
		}
		
		.social_icons{
			gap: 0.625rem;

			a{
				width: 2.5rem;
				height: 2.5rem;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 1px solid $white;
				border-radius: 50%;
				
				.icon{
					width: 1.875rem;
					height: 1.875rem;

					object{
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}


//modal
.modal{
    &-dialog{
        justify-content: center;
    }

    &-content{
        overflow: hidden;
        border-radius: 10px;
    }

    &-body{
        text-align: center;
    }

    &-footer{
        padding: 0;
        border-top: none;
    }

	&-single{
		.modal-content{
			width: 18.125rem;   
		}

		.modal-body{
			padding: 2rem 1.25rem;

			h5{
				margin-bottom: 1rem !important;
			}
	
			p{
				margin-bottom: 0;
				font-size: 0.9rem;
				
				&:nth-child(1){
					margin-bottom: 0.625rem;
					font-weight: 700;
					font-size: 1.1rem;
				}
			}
		}

		.modal-footer{
			.btn{
				width: 100%;
				height: 3.125rem;
				margin: 0;
				border-radius: 0;
			}
		}
	}

	&-password{
		--bs-modal-border-width: 0;

		.modal-content{
			width: 28.125rem;
			padding: 2.25rem 2.5rem;
			border-radius: 0.625rem;
			box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);
		}

		.modal-body{
			h5{
				font-size: $body-text-18;
				font-weight: 700;
				margin-bottom: 1.5rem;
			}

			p{
				margin-bottom: 0.875rem;
			}

			input{
				padding: 0.75rem 1rem;
				width: 100%;
				border-radius: 0.1875rem;
				border: 1px solid $bordergray;

				&::placeholder{
					color: $disabledtext;
				}
			}
		}
	}
}

//floating btn
.floating_btn_group{
    position: fixed;
    bottom: 2.1875rem;
    right: 2.1875rem;
    z-index: 2001;

    &.show {
        .floating_btns{
            .contact_btn{
                opacity: 1;
    
				&:nth-child(1) {
                    transform: translateY(-4.3125rem);   
                }

                &:nth-child(2) {
                    transform: translateY(-8.185rem);
                    
                }
                &:nth-child(3) {
                    transform: translateY(-12.0575rem);
                }
            }

			.floating_btn{
				opacity: 1;

				.floating_open{
					opacity: 0;
				}

				.floating_close{
					opacity: 1;
				}
			}
        }
    }

    .floating_btns{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

		.contact_btn{
			background-color: $white;
			box-shadow: 2px 2px 10px rgba($black, 0.1);
		}

        .contact_btn, .floating_btn{
            position: absolute;
            display: flex;
            align-items: center; 
            justify-content: center;
            width: 3.3125rem;
			height: 3.3125rem;
			padding: 0;
            border-radius: 50%;
            cursor: pointer;
            transition: all .3s ease;
            opacity: 0;

			a{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
			}

			span{
				position: absolute;
				right: 3.8125rem;
				padding: 0.25rem 0.5rem;
				white-space: nowrap;
				color: $white;
				font-size: 0.9rem;
				font-weight: 500;
				background-color: $gray-800;
				cursor: default;
			}
        }

		.floating_btn{
			position: relative;
			width: 3.6875rem;
			height: 3.6875rem;
			background-color: $support;
			box-shadow: none;
			opacity: 1;

			.floating_open{
				position: absolute;
				bottom: 0.06rem;
				right: 0.06rem;
				opacity: 1;
				transition: 0.3s;
			}
			
			.floating_close{
				position: absolute;
				top: calc(50% - 0.875rem);
				left: calc(50% - 0.875rem);
				opacity: 0;
				transition: 0.3s;
			}
		}
    }
}

//pagination
.pagination_line_pc{
    .page-item{
        display: flex;
        align-items: center;
		margin-top: 2.75rem;

        span, a{
            margin: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
            padding: 0 0.25rem;
            color: $gray-600;
            border: none;
            

            &:hover, &:focus{
                color: $primary;
                background-color: transparent;
                border-radius: 0;
            }
        }

        &.active{
            span,a{
                position: relative;
                bottom: -1px;
                color: $primary;
                background-color: transparent;
                border-bottom: 2px solid $primary;
            }
        }

        &:first-child, &:last-child{
            span,a {
                font-size: 1.5rem;
                color: $gray-900;
            }
        }
    }
}

//input radio
input[type='radio'] {
	-webkit-appearance: none; 
	-moz-appearance: none; 
	appearance: none; 
	width: 1.25rem;
	min-width: 1.25rem;
	height: 1.25rem;
	border: 1px solid $bordergray; 
	border-radius: 50%;
	outline: none;
	cursor: pointer;

	&:checked {
		background-color: $mainblue; 
		border: 4px solid $white; 
		box-shadow: 0 0 0 1px $mainblue;
	}

	& + label{
		padding-left: 0.56rem;
	}
}

.option_box{
	.option_group{
		position: relative;

		input[type="radio"]{
			display: none;

			&:checked+label{
				background-color: $primary;
				color: $white;
				border: 1px solid $primary;
			}
		}
		label{
			margin-right: 0.5rem;
			margin-bottom: 0.5rem;
			padding: 0.625rem 0.75rem;
			font-size: 0.9rem;
			background-color: $white;
			color: $graytext;
			border: 1px solid $bordergray;
			border-radius: 5px;
			z-index: 20;

			&:last-child{
				margin-right: 0;
			}
		}
	}
}

//button - 현재 적용안되어있는 경우가 많음. 조금씩 적용시켜나갈 예정. 버튼 형태도 추가 예정
.large_btn{
	width: 16.5rem;
	padding: 1rem 0.625rem;
	border-radius: 0.3125rem;
}

.small_btn{
	width: 8.375rem;
	padding: 0.75rem 1.625rem;
	border-radius: 2.375rem;
}

.modal_btn{
	width: 4.75rem;
	padding: 0.75rem;
	border-radius: 0.3125rem;
}

.white_btn{
	background-color: $white;
	border: 1px solid $bordergray;
	transition: 0.2s;
	color: $graytext;

	&:hover{
		background-color: #ebebeb;
	}
}

.gray_btn{
	background-color: $lightgray_2;
    border: 1px solid $lightgray_2;
    color: $graytext;
	transition: 0.2s;

    &:hover{
        background-color: #CBCCCE;
    }
}

.blue_btn{
	background-color: $buttonblue;
	transition: 0.2s;
	color: $white;

	&:hover{
		background-color: $btnhover;
	}
}

.black_btn{
	background-color: $textlable;
	color: $white;

}

.darknavy_btn{
	background-color: $darknavy;
	color: $white;

	transition: 0.2s;

	&:hover{
		background-color: $textlable;
	}
}

.red_btn{
	background-color: $support;
	color: $white;
	transition: 0.2s;

	&:hover{
		background-color: #EB4B34;
	}
}

.more_btn{
	padding: 0.5rem 1.25rem;
	border-radius: 1.25rem;
	font-size: $body-text-12;
}

//header font
h1, h2, h3, h4, h5, h6, p, span{
	margin-bottom: 0;
	line-height: normal;
}
h1{
	font-size: $h1-font-size;
	font-weight: 700;
}
h2{
	font-size: $h2-font-size;
	font-weight: 700;
}
h3{
	font-size: $h3-font-size;
	font-weight: 500;
}
h4{
	font-size: $h4-font-size;
	font-weight: 500;
}
h5{
	font-size: $h5-font-size;
	font-weight: 500;
}
h6{
	font-size: $h6-font-size;
	font-weight: 500;
}

ul, ol{
	padding-left: 0;
}

//editor
blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
}
pre.ql-syntax {
    background-color: #23241f;
    color: #f8f8f2;
    overflow: visible;
	border-radius: 0.475rem;
	white-space: pre-wrap;
    margin-bottom: 5px;
    margin-top: 5px;
    padding: 5px 10px;
}

//spinner
.spinner-container{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba($black, 0.5);
	z-index: 20000;
}

.spinner{
	position: fixed;
}

// number 증가 감소 화살표 삭제
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  	-webkit-appearance: none;
  	margin: 0;
}

pre{
	overflow: auto;
	white-space: pre-wrap; /* pre tag내에 word wrap */
}

//ck-editor
.ck-content h1, 
.ck-content h2, 
.ck-content h3, 
.ck-content h4, 
.ck-content h5, 
.ck-content h6{
	margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 600;
    line-height: 1.2;
}

.ck-content p{
	margin-bottom: 0.3rem;
	line-height: inherit;
}

.ck-content span{
	line-height: inherit;
}

.ck-content{
	ol{
		padding-left: 2rem;
		list-style-type: decimal;

		li{
			list-style-type: decimal;
		}
	}

	ul{
		padding-left: 2rem;
		list-style-type: disc;

		li{
			list-style-type: disc;
		}
	}

	img{
		max-width: 100%;
		height: auto;
	}
}

.ck-editor__editable_inline{
	min-height: auto !important;
}

//vue 로딩 관련
[v-cloak] {
    display: none;
}


.gray_text{
    color: $graytext;
}

//share
.share{
	.share_btn{
		padding: 0;
		color: $graytext;
		font-size: $body-text-14;

		&::after{
			display: none;
		}
	}

	.dropdown-menu{
		width: 31rem;
		margin-top: 0.5rem !important;
		padding: 1.5rem;
		border: none;
		border-radius: 0.75rem;
		box-shadow: 1px 2px 8px 0px rgba(0, 0, 0, 0.20);

		.url_form{
			gap: 0.375rem;
			margin-top: 1.125rem;

			#shareUrlInput{
				width: 100%;
				padding: 0.75rem 0.875rem;
				color: $darknavy;
				font-size: $body-text-14;
				border-radius: 0.3125rem;
				border: 1px solid $bordergray;
				background-color: $lightgray;
			}

			.copy_btn{
				padding: 0.625rem;
				border-radius: 0.3125rem;
				white-space: nowrap;
			}
		}

		.copydone{
			display: none;
			margin-top: 0.3125rem;
			color: $mainblue;
			font-size: $body-text-14;
		}
	}
}